import * as actionTypes from "./actionTypes";
import { setTokenHeader, removeTokenHeader } from "../../utils/api";

// eslint-disable-next-line no-undef
const token = localStorage.getItem("token");

const initialState = {
  signIn: {
    requesting: false,
    errors: []
  },
  user: {
    token,
    requesting: false,
    error_fetching: {}
  },
  enterprise: [],
  information: {
    productOffer: [],
    serviceOffer: [],
    provinces: [
      {
        id: 1,
        title: "Oujda-Angad"
      },
      {
        id: 2,
        title: "Nador"
      },
      {
        id: 3,
        title: "Berkane"
      },
      {
        id: 4,
        title: "Taourirt"
      },
      {
        id: 5,
        title: "Jerada"
      },
      {
        id: 6,
        title: "Figuig"
      },
      {
        id: 7,
        title: "Driouch"
      },
      {
        id: 8,
        title: "Guercif"
      }
    ]
  },
  meta: {
    resetPasswordLinkSent: false,
    verifyingResetPasswordTk: true,
    validPasswordToken: false,
    resetPasswordStatus: "unset"
  }
};

function homeReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SIGN_IN_REQUEST:
      return { ...state, signIn: { requesting: true } };

    case actionTypes.SIGN_IN_SUCCESS:
      return { ...state, signIn: { requesting: false } };

    case actionTypes.SIGN_IN_ERROR:
      return { ...state, signIn: { requesting: false, error: action.payload } };

    case actionTypes.SIGN_IN_CANCELLED:
      return { ...state, signIn: { requesting: false } };

    case actionTypes.SAVE_TOKEN:
      // eslint-disable-next-line no-undef
      localStorage.setItem("token", action.token);
      setTokenHeader(action.token);
      return {
        ...state,
        signIn: { requesting: false },
        user: { ...state.user, token: action.token }
      };
    case actionTypes.DELETE_TOKEN:
      // eslint-disable-next-line no-undef
      localStorage.removeItem("token");
      removeTokenHeader();
      return {
        ...state,
        signIn: { requesting: false },
        user: { ...state.user, token: null }
      };

    case actionTypes.REQUEST_USER:
      return { ...state, user: { ...state.user, requesting: true } };
    case actionTypes.REQUEST_USER_SUCCESS:
      return {
        ...state,
        user: { ...action.user, ...state.user },
        enterprise: { ...action.enterprise, ...state.enterprise }
      };
    case actionTypes.REQUEST_RESET_PASSWORD_SUCCESS:
      return { ...state, meta: { ...state.meta, resetPasswordLinkSent: true } };

    case actionTypes.VALIDATE_RESET_PASSWORD_TOKEN_STATUS:
      if (action.status === 404) {
        return {
          ...state,
          meta: {
            ...state.meta,
            validPasswordToken: false,
            verifyingResetPasswordTk: false
          }
        };
      }
      return {
        ...state,
        meta: {
          ...state.meta,
          validPasswordToken: true,
          verifyingResetPasswordTk: false
        }
      };

    case actionTypes.RESET_PASSWORD_STATUS:
      return { ...state, meta: { ...state.meta, resetPasswordStatus: "ok" } };

    case actionTypes.REQUEST_USER_ERROR:
      return {
        ...state,
        user: { error_fetching: action.payload, ...state.user }
      };

    case actionTypes.SET_HOME_INFORMATION:
      return {
        ...state,
        information: { ...state.information, ...action.information }
      };

    case actionTypes.REQUEST_HOME_INFORMATION_ERROR:
      return {
        ...state,
        information: {
          ...state.information,
          requestingInformationError: action.error
        }
      };
    case actionTypes.LOG_OUT_REQUESTING_SUCCESS:
      // eslint-disable-next-line no-undef
      window.location.replace("/");

      return { ...state, user: {} };
    default:
      return state;
  }
}

export default homeReducer;
