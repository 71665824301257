import * as actionTypes from "./actionTypes";

export const requestSearch = (searchObject) => ({
  searchObject,
  type: actionTypes.REQUEST_SEARCH
});

export const setSearchData = (searchData) => ({
  searchData,
  type: actionTypes.SET_SEARCH_DATA
});
export const setRequestSearchDataError = (error) => ({
  type: actionTypes.REQUEST_SEARCH_DATA_ERROR,
  error
});
export const requestSearchData = () => ({
  type: actionTypes.REQUEST_SEARCH_DATA
});

export const requestSearchSuccess = (payload) => {
  return {
    searchResult: payload,
    type: actionTypes.SET_SEARCH_RESULT
  };
};

export const requestSearchPage = (page) => ({
  page,
  type: actionTypes.REQUEST_SEARCH_PAGE
});
export const requestSearchError = (payload) => ({
  searchResult: payload,
  type: actionTypes.SET_SEARCH_ERROR
});
