import React from "react";
import Proptypes from "prop-types";
import { Redirect, Route } from "react-router-dom";
import LoadRoute from "./LoadRoute";
import Header from "../components/header";
import Footer from "../components/footer";

const DenyAccess = ({ component, isAuthenticated, ...props }) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Route {...props}>
      <Header />
      {!isAuthenticated ? (
        <LoadRoute component={component} />
      ) : (
        <Redirect to="/" />
      )}
      <Footer />
    </Route>
  );
};
DenyAccess.propTypes = {
  component: Proptypes.func.isRequired,
  isAuthenticated: Proptypes.bool.isRequired
};

export default DenyAccess;
