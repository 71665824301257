import React from "react";
import styled from "styled-components";
import { Button as AntButton } from "antd";
import PropTypes from "prop-types";

const StyledButton = styled(AntButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => (props.height ? props.height : "fit-content")};
  width: ${(props) => (props.width ? props.width : "fit-content")};
  span {
    font-weight: 400;
    padding: 16px 20px;
  }

  margin: 0;
`;

const Button = ({
  children,
  className,
  type,
  onClick,
  disabled,
  htmlType,
  loading,
  style,
  size
}) => {
  return (
    <StyledButton
      className={className}
      htmlType={htmlType}
      disabled={disabled}
      type={type}
      onClick={onClick}
      loading={loading}
      style={style}
      size={size}
    >
      {children}
    </StyledButton>
  );
};
Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  htmlType: PropTypes.string,
  size: PropTypes.string,
  style: PropTypes.shape({})
};
Button.defaultProps = {
  className: "",
  loading: false,
  type: "",
  onClick() {},
  disabled: false,
  htmlType: "button",
  size: "",
  style: {}
};
export default Button;
