import { combineReducers } from "redux";

import inscriptionReducer from "./inscription/reducers";
import homeReducer from "./home/reducers";
import searchReducer from "./search/reducer";
import singleEnterpriseReducer from "./entreprise-single/reducers";
import errorReducer from "./errorHandeler/reducers";

const rootReducer = combineReducers({
  inscription: inscriptionReducer,
  home: homeReducer,
  search: searchReducer,
  singleEnterprise: singleEnterpriseReducer,
  errorHandeler: errorReducer
});
export default rootReducer;
