/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import { Divider } from "antd";
import styled from "styled-components";
import { Input as FormikInput } from "formik-antd";

const StyledInputWrapper = styled("div")`
  input {
    border: ${(props) => (props.divider ? "none" : "")};
    &:focus {
      box-shadow: none;
    }
  }
  .divider {
    margin: 0;
  }
`;
const CustomInput = ({ divider, type, ...props }) => {
  return (
    <StyledInputWrapper divider={divider}>
      {type === "password" ? (
        <FormikInput.Password
          style={{ border: "none" }}
          bordered={false}
          {...props}
        />
      ) : (
        <FormikInput bordered={false} {...props} />
      )}
      {divider && <Divider className="divider" />}
    </StyledInputWrapper>
  );
};

CustomInput.propTypes = {
  divider: PropTypes.bool,
  type: PropTypes.string
};
CustomInput.defaultProps = {
  divider: false,
  type: "text"
};
export default CustomInput;
