import axios from "axios";

// eslint-disable-next-line
export const baseURL =
  process.env.NODE_ENV === "production"
    ? "https://www.intajoriental.com/back-end/public/api"
    : "/api";

// eslint-disable-next-line no-undef
const token = localStorage.getItem("token");

const headers = { Authorization: `Bearer ${token}` };
const instance = axios.create({
  baseURL,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json",
    ...headers
  }
});
export const setTokenHeader = (tokenArg) => {
  instance.defaults.headers.Authorization = `Bearer ${tokenArg}`;
};

export const removeTokenHeader = () => {
  instance.defaults.headers.post.Authorization = null;
};
export default instance;
