import React, { Suspense } from "react";
import PropTypes from "prop-types";
import { Spin } from "antd";
// code splitting: lazily load route components when needed (improves app initial loading speed).
const LoadRoute = ({ component, props }) => {
  const LazyComponent = React.lazy(() => component());
  return (
    <Suspense
      fallback={
        <Spin
          style={{ height: 600, margin: "24px auto 0 auto ", display: "block" }}
        />
      }
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <LazyComponent {...props} />
    </Suspense>
  );
};

LoadRoute.propTypes = {
  component: PropTypes.string.isRequired,
  props: PropTypes.shape({}).isRequired
};

export default LoadRoute;
