import * as actionTypes from "./actionTypes";

export const requestInformation = () => ({
  requestingInformation: true,
  type: actionTypes.REQUEST_INSCRIPTION_INFORMATION
});

export const setInscriptionInformation = (payload) => ({
  payload,
  type: actionTypes.SET_INSCRIPTION_INFORMATION
});

export const addEnterprise = (payload) => ({
  payload,
  type: actionTypes.ADD_ENTERPRISE_INFORMATION
});

export const setRequestingAddEnterprise = (payload) => ({
  payload,
  type: actionTypes.REQUEST_ADD_ENTERPRISE
});
export const addEnterpriseSuccess = () => ({
  type: actionTypes.ADD_ENTERPRISE_INFORMATION_SUCCESS
});
export const addEnterpriseError = (error) => ({
  error,
  type: actionTypes.ADD_ENTERPRISE_INFORMATION_ERROR
});
