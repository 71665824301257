import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Popover, Layout, Typography } from "antd";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import { Form as FormikForm } from "formik-antd";
import styled from "styled-components";
import * as Yup from "yup";
import history from "utils/history";
import { ReactComponent as Logo } from "assets/images/Logo.svg";

import {
  signInRequesting,
  signInError as signInErrorSelector,
  user,
  userIsConnected,
  entreprise as entrepriseSelector
} from "../redux-store/home/selectors";
import { signInRequest, logOutRequest } from "../redux-store/home/actions";

import MenuToggleIcon from "./icons/menuToggleIcon";
import Breakpoint from "../utils/break-points";
import Button from "./button";
import UserIcon from "./icons/userIcon";
import CustomInput from "./customInput";

const { Text } = Typography;

const AntdHeader = Layout.Header;
const StyledHeader = styled(AntdHeader)`
  box-shadow: 0 4px 16px rgba(29, 43, 86, 0.04);
  position: relative;
  z-index: 100;
  @media only screen and (min-width: ${Breakpoint.md}) {
    box-shadow: none;
    height: 80px;
  }
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        height: 42px;
      }
    }
    button {
      display: flex;
    }

    @media only screen and (min-width: ${Breakpoint.md}) {
      .toggle-icon {
        display: none;
      }
    }
  }

  .current-user {
    max-width: 150px;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    span {
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      @media only screen and (max-width: ${Breakpoint.xs}) {
        display: none;
      }
    }
    svg {
      margin-right: 8px;
      min-height: 24px;
      min-width: 24px;
      display: inline-block;
    }
  }
  .authentication {
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      &.add-enterprise {
        margin: 0 0 0 48px;
        height: 48px;
        display: inline-block;
        padding: 12px 20px;
        span {
          padding: 0;
        }
      }
    }
    .connect {
      color: var(--primary-blue);
      font-weight: 400;
      line-height: 1.5rem;
      cursor: pointer;
      position: relative;
      display: flex;
      div[role="button"] {
        display: flex;
        align-items: center;
      }
      svg {
        margin-right: 16px;
        position: relative;
        bottom: 2px;
      }
    }
  }
  @media only screen and (max-width: ${Breakpoint.md}) {
    .authentication {
      display: block;
      top: -188%;
      transition: top linear 200ms;
      background-color: white;
      z-index: -1;
      position: absolute;
      width: 100%;
      left: 0;
      button {
        display: block;
      }
      button.add-enterprise {
        width: 100%;
        margin: 0;
        border-radius: 0;
      }
      .connect {
        height: 48px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &.toggle {
        top: 100%;
      }
    }
  }
`;
const MenuToggle = styled("button")`
  border: none;
  background: transparent;
`;

const StyledConnect = styled("div")`
  text-align: center;
  margin-top: 24px;
  padding-left: 8px;
  padding-right: 8px;

  .email {
    margin-bottom: 10px;
  }

  .password {
    margin-bottom: 10px;
  }
  .forgot-password {
    text-align: center;
    margin-top: 24px;
  }
  .connect-button {
    margin: 0 auto;
    height: 48px;
    width: 224px;
  }
`;

const Header = ({
  requesting,
  signInRequestAction,
  signInError,
  isConnected,
  currentUser,
  logOutRequestAction,
  entreprise
}) => {
  const userPopOver = () => {
    return (
      <>
        <Button
          style={{ marginBottom: "10px", width: "100%" }}
          onClick={() => history.push(`/entreprise/${entreprise.id}`)}
          type="primary"
        >
          Mon Entreprise
        </Button>
        <Button
          size="small"
          style={{ marginBottom: "10px", width: "100%" }}
          onClick={() => history.push(`/my-account`)}
          type="primary"
        >
          Mon Compte
        </Button>
        <Button
          style={{ width: "100%" }}
          size="small"
          onClick={() => logOutRequestAction()}
        >
          Se déconnecter
        </Button>
      </>
    );
  };

  const [toggleMenu, setToggleMenu] = React.useState(false);
  const [loginPopOver, setLoginPopOver] = React.useState(false);
  const logInPopOver = () => (
    <Formik
      onSubmit={(values) => signInRequestAction(values)}
      initialValues={{ email: "", password: "" }}
      validationSchema={Yup.object().shape({
        email: Yup.string("email/username requis").required(
          "le champ est requis"
        ),
        password: Yup.string("mot de passe est requis").required(
          "le champ est requis"
        )
      })}
    >
      <Form>
        <StyledConnect>
          <FormikForm.Item name="email">
            <CustomInput
              className="email"
              divider
              name="email"
              type="text"
              placeholder="Email / User Name"
            />
          </FormikForm.Item>
          <FormikForm.Item name="password">
            <CustomInput
              className="password"
              name="password"
              divider
              type="password"
              placeholder="Password"
            />
          </FormikForm.Item>
          {signInError.message && (
            <Text type="danger"> Impossible de se connecter.</Text>
          )}

          <Button
            htmlType="submit"
            loading={requesting}
            className="connect-button"
            type="primary"
          >
            Se connecter
          </Button>
          <div className="forgot-password">
            <Link
              onClick={() => {
                setLoginPopOver(false);
              }}
              to="/forgot-password"
            >
              Mot de passe oublié?
            </Link>
          </div>
        </StyledConnect>
      </Form>
    </Formik>
  );
  return (
    <StyledHeader toggle={toggleMenu}>
      <div className="container">
        <Link className="logo" to="/">
          <Logo width={200} />
        </Link>
        {!isConnected && (
          <MenuToggle
            onClick={() => setToggleMenu(!toggleMenu)}
            className="toggle-icon"
          >
            <MenuToggleIcon />
          </MenuToggle>
        )}
        {!isConnected ? (
          <div className={`authentication ${toggleMenu ? "toggle" : ""}`}>
            <div
              role="link"
              tabIndex={-1}
              onKeyDown={() => {}}
              className="connect"
            >
              <Popover
                visible={loginPopOver}
                onVisibleChange={setLoginPopOver}
                content={logInPopOver}
                trigger="click"
              >
                <div role="button" tabIndex={-1}>
                  <UserIcon />
                  Se connecter
                </div>
              </Popover>
            </div>
            <Button
              className="add-enterprise"
              type="primary"
              onClick={() => {
                history.push("/inscription");
                setToggleMenu(false);
              }}
            >
              Ajouter mon entreprise
            </Button>
          </div>
        ) : (
          <Popover content={userPopOver} trigger="click">
            <div className="current-user">
              <UserIcon />
              <span>{currentUser.full_name}</span>
            </div>
          </Popover>
        )}
      </div>
    </StyledHeader>
  );
};

Header.propTypes = {
  requesting: PropTypes.bool,
  signInError: PropTypes.shape({ message: PropTypes.string }),
  currentUser: PropTypes.shape({ full_name: PropTypes.string }),
  isConnected: PropTypes.bool,
  entreprise: PropTypes.shape({ id: PropTypes.number }),
  signInRequestAction: PropTypes.func.isRequired,
  logOutRequestAction: PropTypes.func.isRequired
};
Header.defaultProps = {
  requesting: false,
  signInError: {},
  currentUser: {},
  isConnected: false,
  entreprise: {}
};

function mapStateToProps(state) {
  return {
    requesting: signInRequesting(state),
    signInError: signInErrorSelector(state),
    currentUser: user(state),
    isConnected: userIsConnected(state),
    entreprise: entrepriseSelector(state)
  };
}
export default connect(mapStateToProps, {
  signInRequestAction: signInRequest,
  logOutRequestAction: logOutRequest
})(Header);
