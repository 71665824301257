import {
  call,
  put,
  takeLatest,
  takeEvery,
  fork,
  delay
} from "redux-saga/effects";
import { serialize } from "object-to-formdata";
import { setTokenHeader } from "utils/api";

import {
  REQUEST_INSCRIPTION_INFORMATION,
  ADD_ENTERPRISE_INFORMATION
} from "./actionTypes";
import {
  setInscriptionInformation,
  setRequestingAddEnterprise,
  addEnterpriseError,
  addEnterpriseSuccess
} from "./actions";
import { requestUser, saveToken, requestUerSuccess } from "../home/actions";
import api from "../../utils/api";
import history from "../../utils/history";
import { setError } from "../errorHandeler/actions";

function fetchInscriptionData() {
  return api
    .get("signupInformation")
    .then((resp) => {
      return resp.data;
    })
    .catch((e) => {
      throw e;
    });
}

function* setInscriptioninformation() {
  try {
    const resp = yield call(fetchInscriptionData);
    yield put(setInscriptionInformation(resp));
  } catch (e) {
    console.error(e);
    yield put(setError(e));
  }
}
function* requestInscriptionInformation() {
  yield takeLatest(REQUEST_INSCRIPTION_INFORMATION, setInscriptioninformation);
}
function* addEnterpriseApi({ payload }) {
  try {
    yield put(setRequestingAddEnterprise(true));

    const { enterprise, offers, besoins } = payload;
    const newUser = payload.user;
    const {
      data: {
        // eslint-disable-next-line camelcase
        data: { accessToken, entreprise_id }
      }
    } = yield call(api.post, "auth/signup", {
      enterprise,
      user: { ...newUser }
    });

    setTokenHeader(accessToken);

    // eslint-disable-next-line no-restricted-syntax
    for (const offer of offers) {
      // eslint-disable-next-line no-undef
      const formData = new FormData();

      formData.append("entreprise_id", entreprise_id);
      formData.append("isSignUp", true);

      if (offer?.photos) {
        offer.photos.forEach((photo) => formData.append("photos[]", photo[1]));
      }

      delete offer?.photos;

      const requestObject = serialize(offer, null, formData);

      yield call(api.post, "offers", requestObject, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const besoin of besoins) {
      // eslint-disable-next-line no-undef
      const besoinsFormdata = new FormData();
      besoinsFormdata.append("entreprise_id", entreprise_id);
      if (besoin?.photos) {
        besoin.photos.forEach((photo) =>
          besoinsFormdata.append("photos[]", photo[1])
        );
      }
      delete besoin?.photos;
      besoin.is_product = Boolean(besoin.type);
      const requestObjectBesoin = serialize(besoin, null, besoinsFormdata);
      yield call(api.post, "needs", requestObjectBesoin, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
    }
    yield put(setRequestingAddEnterprise(false));
    yield put(addEnterpriseSuccess());
    yield put(requestUser());

    const {
      data: {
        data: { user, entreprise }
      }
    } = yield call(api.get, "auth/user");

    yield put(requestUerSuccess({ user, enterprise: entreprise }));
    yield delay(5000);
    // eslint-disable-next-line camelcase
    yield history.replace(`entreprise/${entreprise_id}`);

    yield put(saveToken(accessToken));
  } catch (e) {
    yield put(setRequestingAddEnterprise(false));
    yield put(addEnterpriseError(e));
    yield put(setError(e));
  }
}
function* addEnterprise() {
  yield takeEvery(ADD_ENTERPRISE_INFORMATION, addEnterpriseApi);
}

function* saga() {
  yield fork(requestInscriptionInformation);
  yield fork(addEnterprise);
}

export default saga;
