import { message } from "antd";

import * as actionTypes from "./actionTypes";

const initialState = {
  enterprise: { title: "" },
  productOffers: [],
  serviceOffers: [],
  needs: [],
  badges: [],
  meta: {
    requestingEntreprise: true,
    addLoading: false,
    modalIsVisible: false,
    editLoading: false,
    editModalIsVisible: false
  }
};
export default function singleEnterpriseReducer(state = initialState, action) {
  let meta = {};
  switch (action.type) {
    case actionTypes.REQUEST_ENTERPRISE:
      return { ...state, meta: { ...state.meta, requestingEntreprise: true } };

    case actionTypes.SET_ENTERPRISE:
      return {
        ...state,
        enterprise: action.enterprise.enterprise,
        productOffers: action.enterprise.productOffers,
        serviceOffers: action.enterprise.serviceOffers,
        badges: action.enterprise.badges,
        needs: action.enterprise.needs,
        meta: { ...state.meta, requestingEntreprise: false }
      };
    case actionTypes.DELETE_OFFER_SUCCESS:
      if (action.type_id === 1) {
        message.success({
          content: "Service supprimer avec succès."
        });
        return {
          ...state,
          serviceOffers: state.serviceOffers.filter(
            (service) => service.id !== action.id
          )
        };
      }
      message.success({
        content: "Produit supprimer avec succès."
      });
      return {
        ...state,
        productOffers: state.productOffers.filter(
          (product) => product.id !== action.id
        )
      };

    case actionTypes.DELETE_NEED_SUCCESS:
      message.success({
        content: "Besoin supprimer avec succès."
      });
      return {
        ...state,
        needs: state.needs.filter((need) => need.id !== action.id)
      };

    case actionTypes.SHOW_EDIT_MODAL:
      return {
        ...state,
        meta: { ...state.meta, editModalIsVisible: action.show }
      };

    case actionTypes.EDIT_NEED:
      return { ...state, meta: { ...state.meta, editLoading: true } };
    case actionTypes.EDIT_OFFER:
      return { ...state, meta: { ...state.meta, editLoading: true } };

    case actionTypes.EDIT_OFFER_SUCCESS:
      meta = { editLoading: false, editModalIsVisible: false };
      if (action.type_id === 1) {
        message.success({
          content: "Service modifier avec succès."
        });
        return {
          ...state,
          serviceOffers: [
            ...state.serviceOffers.filter(
              (service) => service.id !== action.offer.id
            ),
            action.offer
          ],
          meta: { ...state.meta, ...meta }
        };
      }
      message.success({
        content: "Produit modifier avec succès."
      });
      return {
        ...state,
        productOffers: [
          ...state.productOffers.filter(
            (offer) => offer.id !== action.offer.id
          ),
          action.offer
        ],
        meta: { ...state.meta, ...meta }
      };

    case actionTypes.EDIT_NEED_SUCCESS:
      return {
        ...state,
        needs: [
          ...state.needs.filter((need) => need.uid !== action.need.uid),
          action.need
        ],
        meta: { ...state.meta, editModalIsVisible: false, editLoading: false }
      };
    case actionTypes.ADD_NEED:
      return { ...state, meta: { ...state.meta, addLoading: true } };
    case actionTypes.ADD_NEED_SUCCESS:
      message.success({
        content: "Besoin ajouté avec succès."
      });
      return {
        ...state,
        needs: [...state.needs, action.need],
        meta: { ...state.meta, addLoading: false, modalIsVisible: false }
      };

    case actionTypes.ADD_OFFER:
      return { ...state, meta: { ...state.meta, addLoading: true } };

    case actionTypes.ADD_OFFER_SUCESS:
      meta = { addLoading: false, modalIsVisible: false };
      if (action.offer.type_id === 1) {
        message.success({
          content: "Service ajouté avec succès."
        });
        return {
          ...state,
          serviceOffers: [...state.serviceOffers, action.offer],
          meta: { ...state.meta, ...meta }
        };
      }
      message.success({
        content: "Offre ajouté avec succès."
      });
      return {
        ...state,
        productOffers: [...state.productOffers, action.offer],
        meta: { ...state.meta, ...meta }
      };

    case actionTypes.SHOW_MODAL:
      return { ...state, meta: { ...state.meta, modalIsVisible: action.show } };

    default:
      return state;
  }
}
