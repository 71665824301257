import { notification } from "antd";
import { SET_ERROR } from "./actionTypes";

const codeMessage = {
  200: "Response Success。",
  201: "New or modified data successfully.",
  202: "A request has entered the background queue (asynchronous task).",
  204: "Data deleted successfully.",
  400: "There was an error in the request sent, and the server did not create or modify data.",
  401: "Vos identifiant sont incorrect",
  403: "The user is authorized, but access is forbidden。",
  404: "Not found.",
  406: "The requested format is not available。",
  410: "The requested resource is permanently deleted and will no longer be available.",
  422: "When creating an object, a validation error occurred.",
  500: "An error occurred on the server, please check the server admin.",
  502: "Gateway error.",
  503: "The service is unavailable, the server is temporarily overloaded or maintained.",
  504: "Gateway timeout."
};
const errorHandler = (error) => {
  const { response } = error;

  if (response && response.status) {
    const errorText = codeMessage[response.status] || response.statusText;
    notification.error({
      message: `Une erreur est survenue!`,
      description: errorText
    });
  } else if (!response) {
    notification.error({
      description: "Could not connect to the server",
      message: "Connection problem"
    });
  }

  return response;
};

const initialState = {
  error: null
};

export default function errorReducer(state = initialState, action) {
  const { error, type } = action;
  if (type === SET_ERROR) {
    errorHandler(error);
    return error ? { error } : state;
  }
  return state;
}
