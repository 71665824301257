import { fork } from "redux-saga/effects";
import inscriptionSaga from "./inscription/saga";
import {
  signInFlow,
  getUserFlow,
  getHomeInformation,
  logOutFlow,
  resetPassword,
  validateResetPasswordToken,
  resetPasswordLink
} from "./home/saga";
import { getSearchDataWatcher } from "./search/saga";
import singleEntrepriseWatcher from "./entreprise-single/saga";

export default function* rootSaga() {
  yield fork(inscriptionSaga);
  yield fork(signInFlow);
  yield fork(logOutFlow);
  yield fork(getUserFlow);
  yield fork(getHomeInformation);
  yield fork(getSearchDataWatcher);
  yield fork(singleEntrepriseWatcher);
  yield fork(resetPasswordLink);
  yield fork(resetPassword);
  yield fork(validateResetPasswordToken);
}
