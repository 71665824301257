/* eslint-disable camelcase */
import { takeEvery, call, put } from "redux-saga/effects";
import { serialize } from "object-to-formdata";
import { Modal } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import React from "react";
import * as actionTypes from "./actionTypes";
import api from "../../utils/api";
import {
  setEnterprise,
  deleteOfferSuccess,
  deleteNeedSucces,
  editOfferSucces,
  editNeedSuccess,
  addNeedSuccess,
  addOfferSuccess
} from "./actions";
import { setError } from "../errorHandeler/actions";

function* getEnterprise({ id }) {
  try {
    const {
      data: { data }
    } = yield call(api.get, `/enterprises/${id} `);
    yield put(setEnterprise(data));
  } catch (e) {
    console.error(e);
    setError(e);
  }
}
function confirmPromise({ name }, apiDelete) {
  return new Promise((resolve, reject) => {
    Modal.confirm({
      title: "Suppression",
      // eslint-disable-next-line react/jsx-filename-extension
      icon: <WarningOutlined />,
      content: `Confirmer la suppression de cette article : ${name}  !!`,
      okText: "Confirmer",
      cancelText: "Annuler",
      onOk() {
        return apiDelete()
          .then((success) => resolve(success))
          .catch((error) => {
            setError(error);
            return reject();
          });
      },
      onCancel() {
        reject();
      },
      okButtonProps: {}
    });
  });
}

function* deleteOffer({ id, type_id, name }) {
  try {
    // console.log('object to form data ',serialize({ is_product: isProduct, _method: 'delete' }))
    yield confirmPromise({ id, type_id, name }, () =>
      api.delete(`/offers/${id}`, {
        // headers: { 'Content-Type': 'multipart/form-data' },
        data: { type_id, _method: "delete" }
      })
    );
    yield put(deleteOfferSuccess(id, type_id));
  } catch (e) {
    console.error(e);
    setError(e);
  }
}
function* deleteNeed({ id, type_id, name }) {
  try {
    yield confirmPromise({ id, type_id, name }, () =>
      api.delete(`/needs/${id}`, {
        // headers: { 'Content-Type': 'multipart/form-data' },
        data: { type_id, _method: "delete" }
      })
    );
    yield put(deleteNeedSucces(id, type_id));
  } catch (e) {
    console.error(e);
    setError(e);
  }
}

function* editOffer({ values, is_product }) {
  try {
    const formData = serialize({ is_product, _method: "put" });
    if (values.photos) {
      values.photos.forEach((photo) => {
        if (!(typeof photo === "string")) {
          formData.append("photos[]", photo[1]);
        } else {
          formData.append("photos[]", photo);
        }
      });
      // eslint-disable-next-line no-param-reassign
      delete values.photos;
    }

    // console.log('object to form data ',serialize({ is_product: isProduct, _method: 'delete' }))
    const {
      data: { data }
    } = yield call(
      api.post,
      `/offers/${values.id}`,
      serialize({ ...values }, null, formData)
    );
    // yield put();
    yield put(editOfferSucces(data.offer, is_product));
  } catch (e) {
    console.error(e);
    setError(e);
  }
}

function* editNeed({ need }) {
  try {
    // eslint-disable-next-line no-undef
    const formData = new FormData();

    if (need.photos) {
      need.photos.forEach((photo) => {
        if (!(typeof photo === "string")) {
          formData.append("photos[]", photo[1]);
        } else {
          formData.append("photos[]", photo);
        }
      });
      // eslint-disable-next-line no-param-reassign
      delete need.photos;
    }
    const requestFormData = serialize(
      { ...need, _method: "put" },
      null,
      formData
    );

    // console.log('object to form data ',serialize({ is_product: isProduct, _method: 'delete' }))
    const {
      data: { data }
    } = yield call(api.post, `/needs/${need.id}`, requestFormData);
    // yield put();
    yield put(editNeedSuccess(data.need));
  } catch (e) {
    console.error(e);
    setError(e);
  }
}

function* addNeed({ need }) {
  try {
    // eslint-disable-next-line no-undef
    const formData = new FormData();
    if (need.photos) {
      need.photos.forEach((photo) => formData.append("photos[]", photo[1]));
    }
    // eslint-disable-next-line no-param-reassign
    delete need.photos;

    const requestObject = serialize(need, null, formData);
    const {
      data: { data }
    } = yield call(api.post, "needs", requestObject, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    yield put(addNeedSuccess(data.need));
  } catch (e) {
    console.error(e);
    yield put(setError(e));
  }
}
function* addOffer({ offer }) {
  try {
    // eslint-disable-next-line no-undef
    const formData = new FormData();
    if (offer.photos) {
      offer.photos.forEach((photo) => formData.append("photos[]", photo[1]));
    }
    // eslint-disable-next-line no-param-reassign
    delete offer.photos;

    const requestObject = serialize(offer, null, formData);
    const {
      data: { data }
    } = yield call(api.post, "offers", requestObject, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });

    yield put(addOfferSuccess(data.offer));
  } catch (e) {
    console.error(e);
    yield put(setError(e));
  }
}

export default function* singleEntrepriseWatcher() {
  yield takeEvery(actionTypes.REQUEST_ENTERPRISE, getEnterprise);
  yield takeEvery(actionTypes.DELETE_OFFER, deleteOffer);
  yield takeEvery(actionTypes.DELETE_NEED, deleteNeed);
  yield takeEvery(actionTypes.EDIT_OFFER, editOffer);
  yield takeEvery(actionTypes.EDIT_NEED, editNeed);
  yield takeEvery(actionTypes.ADD_NEED, addNeed);
  yield takeEvery(actionTypes.ADD_OFFER, addOffer);
}
