import * as actionTypes from "./actionTypes";

const initialState = {
  requestingInformation: true,
  requestingAddEnterprise: false,
  addEntrepriseSuccess: false,
  addEntrepriseError: false,
  sectors: [],
  branches: [],
  subBranches: [],
  activities: [],
  provinces: []
};

function inscriptionReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.REQUEST_INSCRIPTION_INFORMATION:
      return { ...state, requestingInformation: true };

    case actionTypes.SET_INSCRIPTION_INFORMATION:
      // eslint-disable-next-line no-case-declarations
      const {
        provinces,
        activities,
        branches,
        subBranches,
        sectors
      } = action.payload;

      return {
        ...state,
        provinces,
        activities,
        subBranches,
        branches,
        sectors,
        requestingInformation: false
      };

    case actionTypes.REQUEST_ADD_ENTERPRISE:
      return { ...state, requestingAddEnterprise: action.payload };
    case actionTypes.ADD_ENTERPRISE_INFORMATION_SUCCESS: {
      return { ...state, addEntrepriseSuccess: true };
    }

    default:
      return state;
  }
}

export default inscriptionReducer;
