import React from "react";
import PropTypes from "prop-types";

const PlusIcon = ({ color }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99992 2.6665C8.36811 2.6665 8.66659 2.96498 8.66659 3.33317V12.6665C8.66659 13.0347 8.36811 13.3332 7.99992 13.3332C7.63173 13.3332 7.33325 13.0347 7.33325 12.6665V3.33317C7.33325 2.96498 7.63173 2.6665 7.99992 2.6665Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.66675 8.00016C2.66675 7.63197 2.96522 7.3335 3.33341 7.3335H12.6667C13.0349 7.3335 13.3334 7.63197 13.3334 8.00016C13.3334 8.36835 13.0349 8.66683 12.6667 8.66683H3.33341C2.96522 8.66683 2.66675 8.36835 2.66675 8.00016Z"
        fill={color}
      />
    </svg>
  );
};
PlusIcon.propTypes = {
  color: PropTypes.string
};
PlusIcon.defaultProps = {
  color: "#1D2B56"
};
export default PlusIcon;
