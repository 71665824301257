export const userIsConnected = (state) => {
  return !!state.home.user.token;
};
export const signInRequesting = (state) => state.home.signIn.requesting;
export const signInError = (state) => state.home.signIn.error;
export const getToken = (state) => state.home.user.token;
export const user = (state) => state.home.user;
export const offers = (state) => state.home.information.productOffer;
export const services = (state) => state.home.information.serviceOffer;
export const entreprise = (state) => state.home.enterprise[0];
export const getSevenSectors = (state) =>
  state.home.information.sectors.slice();
export const resetLinkSentSuccess = (state) =>
  state.home.meta.resetPasswordLinkSent;
export const verifyingResetPasswordTk = (state) =>
  state.home.meta.verifyingResetPasswordTk;
export const validPasswordToken = (state) => state.home.meta.validPasswordToken;
export const resetPasswordStatus = (state) =>
  state.home.meta.resetPasswordStatus;
export const getProvinceById = (state, id) => {
  return state.home.information.provinces.find((province) => {
    return province.id === id;
  });
};
