/* eslint-disable */
export const routes = [
  {
    path: "/",
    component() {
      return import("pages/home");
    },

    exact: true
  },
  {
    path: "/inscription",
    exact: true,

    component() {
      return import("pages/inscription");
    },
    meta: { denyAccess: true }
  },
  {
    path: "/secteur",
    exact: true,

    component() {
      return import("pages/sector");
    }
  },
  {
    path: "/entreprise",

    component() {
      return import("pages/entreprise");
    },
    exact: true
  },
  {
    path: "/entreprise/:id",
    component() {
      return import("pages/enreprise-single");
    }
  },
  {
    path: "/tos",
    exact: true,

    component() {
      return import("pages/tos");
    }
  },
  {
    path: "/forgot-password",
    exact: true,
    component() {
      return import("pages/forgot-password");
    }
  },
  {
    path: "/reset-password",
    exact: true,
    component() {
      return import("pages/reset-password");
    }
  },
  {
    path: "/contact",
    exact: true,
    component() {
      return import("pages/contacter");
    }
  },
  {
    path: "/my-account",
    exact: true,
    component() {
      return import("pages/my-account");
    },
    meta: { allowAccess: true }
  },

  {
    path: "/",
    component() {
      return import("pages/404");
    }
  }
];
