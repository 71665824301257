/* eslint-disable react/no-array-index-key */
import React from "react";
import "antd/dist/antd.less";
import { Layout } from "antd";
import { Router, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ScrollToTop from "utils/ScrollToTop";

import { routes } from "./config/routes";
import LoadRoute from "./utils/LoadRoute";

import "./App.css";
import "./assets/styles/container.less";
import "./assets/styles/globals.scss";

import Header from "./components/header";
import Footer from "./components/footer";

import history from "./utils/history";
import DenyAccess from "./utils/denyAccess";
import { userIsConnected } from "./redux-store/home/selectors";

const { Content } = Layout;

function App({ isConnected }) {
  return (
    <Router history={history}>
      <ScrollToTop />
      <Content className="content">
        <Switch>
          <Route exact path="/inscription-form-only">
            <LoadRoute component={() => import("pages/inscription")} />
          </Route>
          {routes.map(({ path, component, exact, meta }, index) => {
            return meta && meta.denyAccess ? (
              <DenyAccess
                key={index}
                path={path}
                isAuthenticated={isConnected}
                component={component}
              />
            ) : (
              <Route key={index} exact={exact} path={path}>
                {(props) => (
                  <>
                    <Header />
                    <LoadRoute component={component} props={props} />
                    <Footer />
                  </>
                )}
              </Route>
            );
          })}
        </Switch>
      </Content>
    </Router>
  );
}
const mapStateToProps = (state) => ({
  isConnected: userIsConnected(state)
});

App.propTypes = {
  isConnected: PropTypes.bool.isRequired
};

export default connect(mapStateToProps, null)(App);
