import * as actionTypes from "./actionTypes";

const initialState = {
  searchData: {
    sectors: [],
    provinces: [],
    types: [
      { title: "Tous", id: "" },
      { title: "Producteur", id: "producer" },
      { title: "Prestataire de service", id: "serviceProvider" },
      { title: "Commerçant", id: "seller" }
    ]
  },
  searchResult: [],
  meta: { requestingSearchData: false, searchPending: true, error: {} }
};
export default function searchReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.REQUEST_SEARCH_DATA:
      return { ...state, meta: { ...state.meta, requestingSearchData: true } };
    case actionTypes.SET_SEARCH_DATA:
      return {
        ...state,
        searchData: { ...state.searchData, ...action.searchData }
      };
    case actionTypes.REQUEST_SEARCH:
      return { ...state, meta: { ...state.meta, searchPending: true } };
    case actionTypes.SET_SEARCH_RESULT:
      return {
        ...state,
        searchResult: action.searchResult,
        meta: { ...state, searchPending: false }
      };
    case actionTypes.SET_SEARCH_ERROR:
      return state;
    default:
      return state;
  }
}
