/* eslint-disable camelcase */
import * as actionTypes from "./actionTypes";

export const setEnterprise = (enterprise) => ({
  type: actionTypes.SET_ENTERPRISE,
  enterprise
});
export const requestEnterprise = (id) => ({
  type: actionTypes.REQUEST_ENTERPRISE,
  id
});
export const requestEnterpriseError = (error) => ({
  type: actionTypes.REQUEST_ENTERPRISE_ERROR,
  error
});
export const deleteOffer = (id, type_id, name) => ({
  type: actionTypes.DELETE_OFFER,
  id,
  type_id,
  name
});
export const editOffer = (values, is_product) => ({
  type: actionTypes.EDIT_OFFER,
  values,
  is_product
});
export const deleteOfferSuccess = (id, type_id) => ({
  type: actionTypes.DELETE_OFFER_SUCCESS,
  id,
  type_id
});
export const deleteNeed = (id, type_id, name) => ({
  type: actionTypes.DELETE_NEED,
  id,
  type_id,
  name
});
export const deleteNeedSucces = (id) => ({
  type: actionTypes.DELETE_NEED_SUCCESS,
  id
});
export const editOfferSucces = (offer, is_product) => ({
  type: actionTypes.EDIT_OFFER_SUCCESS,
  offer,
  is_product
});
export const editNeed = (need) => ({
  type: actionTypes.EDIT_NEED,
  need
});
export const editNeedSuccess = (need) => ({
  type: actionTypes.EDIT_NEED_SUCCESS,
  need
});

export const addOffer = (offer) => ({
  type: actionTypes.ADD_OFFER,
  offer
});

export const addOfferSuccess = (offer) => ({
  type: actionTypes.ADD_OFFER_SUCESS,
  offer
});

export const addNeed = (need) => ({
  type: actionTypes.ADD_NEED,
  need
});
export const addNeedSuccess = (need) => ({
  type: actionTypes.ADD_NEED_SUCCESS,
  need
});

export const showModal = (show) => ({
  type: actionTypes.SHOW_MODAL,
  show
});
export const showEditModal = (show) => ({
  type: actionTypes.SHOW_EDIT_MODAL,
  show
});
