import * as actionTypes from "./actionTypes";

export const signInRequest = (payload) => ({
  payload,
  type: actionTypes.SIGN_IN_REQUEST
});

export const signInSuccess = () => ({
  type: actionTypes.SIGN_IN_SUCCESS
});

export const saveToken = (token) => ({
  type: actionTypes.SAVE_TOKEN,
  token
});
export const signInError = (error) => ({
  type: actionTypes.SIGN_IN_ERROR,
  payload: error
});
export const deleteToken = () => ({
  type: actionTypes.DELETE_TOKEN
});

export const signInCancelled = () => ({ type: actionTypes.SIGN_IN_CANCELLED });

export const logOutRequest = () => ({
  type: actionTypes.LOG_OUT_REQUESTING
});
export const logOutSuccess = () => ({
  type: actionTypes.LOG_OUT_REQUESTING_SUCCESS
});
export const logOutError = (payload) => ({
  type: actionTypes.LOG_OUT_REQUESTING_SUCCESS,
  error: payload
});

export const requestUser = () => ({ type: actionTypes.REQUEST_USER });

export const requestUerSuccess = (payload) => {
  return {
    type: actionTypes.REQUEST_USER_SUCCESS,
    user: payload.user,
    enterprise: payload.enterprise
  };
};
export const requestUerError = (payload) => ({
  type: actionTypes.REQUEST_USER_ERROR,
  error: payload
});

export const requestHomeInformation = () => ({
  type: actionTypes.REQUEST_HOME_INFORMATION
});
export const setHomeInformation = (payload) => ({
  information: payload,
  type: actionTypes.SET_HOME_INFORMATION
});
export const setHomeInformationError = (payload) => ({
  error: payload,
  type: actionTypes.REQUEST_HOME_INFORMATION_ERROR
});
export const requestPassword = (payload) => ({
  email: payload,
  type: actionTypes.REQUEST_RESET_PASSWORD
});
export const requestPasswordSuccess = () => ({
  type: actionTypes.REQUEST_RESET_PASSWORD_SUCCESS
});

export const validateResetPasswordToken = (payload) => ({
  type: actionTypes.VALIDATE_RESET_PASSWORD_TOKEN_REQUEST,
  token: payload.token
});
export const resetPassword = (token, password, confirmPassword) => ({
  type: actionTypes.RESET_PASSWORD_REQUEST,
  password,
  confirmPassword,
  token
});
export const resetPasswordStatus = (status) => ({
  type: actionTypes.RESET_PASSWORD_STATUS,
  status
});

export const validateResetPasswordStatus = (payload) => ({
  type: actionTypes.VALIDATE_RESET_PASSWORD_TOKEN_STATUS,
  status: payload.status
});
