import {
  call,
  cancelled,
  fork,
  put,
  take,
  takeEvery,
  takeLatest,
  select
} from "redux-saga/effects";
import { notification } from "antd";
import {
  SIGN_IN_REQUEST,
  LOG_OUT_REQUESTING,
  REQUEST_USER,
  REQUEST_HOME_INFORMATION,
  REQUEST_RESET_PASSWORD,
  VALIDATE_RESET_PASSWORD_TOKEN_REQUEST,
  RESET_PASSWORD_REQUEST
} from "./actionTypes";
import {
  signInSuccess,
  saveToken,
  signInError,
  signInCancelled,
  deleteToken,
  requestUerSuccess,
  setHomeInformation,
  logOutSuccess,
  requestPasswordSuccess,
  validateResetPasswordStatus,
  resetPasswordStatus
} from "./actions";
import { setError } from "../errorHandeler/actions";

import api from "../../utils/api";
import history from "../../utils/history";
import { userIsConnected } from "./selectors";

export function* authorize(email, password) {
  try {
    const result = yield api.post("auth/login", { email, password });
    // eslint-disable-next-line camelcase
    const { user, access_token, entreprise } = result.data.data;

    yield put(signInSuccess());
    yield put(saveToken(access_token));
    yield put(requestUerSuccess({ user, enterprise: entreprise }));
    yield history.push(`/entreprise/${entreprise[0].id}`);
  } catch (error) {
    console.log(error);
    yield put(signInError(error));
    yield put(setError(error));
    yield put(setError(error));
  } finally {
    if (yield cancelled()) {
      yield put(signInCancelled());
    }
  }
}
export function* logOut() {
  try {
    yield call(api.get, "auth/logout");
    yield put(deleteToken());
    yield put(logOutSuccess());
  } catch (e) {
    yield put(setError(e));
  }
}

export function* signInFlow() {
  while (true) {
    const {
      payload: { email, password }
    } = yield take(SIGN_IN_REQUEST);
    yield fork(authorize, email, password);
  }
}
export function* logOutFlow() {
  while (true) {
    yield take(LOG_OUT_REQUESTING);

    yield fork(logOut);
  }
}

function* getUser() {
  try {
    const {
      data: {
        data: { user, entreprise }
      }
    } = yield call(api.get, "auth/user");
    yield put(requestUerSuccess({ user, enterprise: entreprise }));
  } catch (e) {
    yield put(setError(e));
    yield put(deleteToken());
    yield put(setError(e));

    // eslint-disable-next-line no-undef
    window.location.replace("/");
  }
}

export function* getUserFlow() {
  const isConnected = yield select(userIsConnected);

  if (isConnected) {
    yield takeEvery(REQUEST_USER, getUser);
  } else {
    // do what ever
  }
}

export function* requestHomeInformation() {
  try {
    const { data: homeInfo } = yield call(api.get, "homeInformation");
    yield put(setHomeInformation(homeInfo));
  } catch (e) {
    yield put(setError(e));
  }
}

export function* getHomeInformation() {
  yield takeEvery(REQUEST_HOME_INFORMATION, requestHomeInformation);
}

function* requestResetPassword(action) {
  try {
    yield api.post("/password/create", { email: action.email });
    yield put(requestPasswordSuccess());
  } catch (e) {
    const { response } = e;
    if (response) {
      notification.error({
        message: `Une erreur est survenue!`,
        description: response.data.message
      });
    }
  }
}
function* validateResetPasswordTokenApi({ token }) {
  try {
    const response = yield api.get(`/password/find/${token}`);
    yield put(validateResetPasswordStatus(response));
  } catch (e) {
    yield put(setError(e));
    if (e.response) {
      yield put(validateResetPasswordStatus(e.response));
    } else {
      validateResetPasswordStatus(e.response);
    }

    console.log(e.response);
  }
}

export function* validateResetPasswordToken() {
  yield takeEvery(
    VALIDATE_RESET_PASSWORD_TOKEN_REQUEST,
    validateResetPasswordTokenApi
  );
}

function* resetPasswordRequest({ password, confirmPassword, token }) {
  try {
    yield put(resetPasswordStatus("loading"));
    yield api.post("password/reset", {
      password,
      password_confirmation: confirmPassword,
      token
    });
    yield put(resetPasswordStatus("ok"));
  } catch (e) {
    yield put(setError(e));
    yield put(resetPasswordStatus("error"));
  }
}

export function* resetPassword() {
  yield takeLatest(RESET_PASSWORD_REQUEST, resetPasswordRequest);
}

export function* resetPasswordLink() {
  yield takeEvery(REQUEST_RESET_PASSWORD, requestResetPassword);
}
