import React, { useState } from "react";
import { Layout, Row, Col, Divider } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import styled from "styled-components";
import PlusIcon from "./icons/PlusIcon";
import Breakpoint from "../utils/break-points";

const AntdFooter = Layout.Footer;
const StylesFooter = styled(AntdFooter)`
  padding-top: 32px;
  font-size: 0.75rem;
  ul {
    list-style: none;
    li {
      display: block;
      margin-bottom: 12px;
    }
  }
  .ant-divider-horizontal {
    margin: 16px 0;
  }
  .copyright {
    color: var(--secondary-bleu);
    .dbfy {
      color: inherit;
      font-weight: 700;
    }
  }
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    .title {
      font-weight: 500;
    }
  }

  .slideable-content {
    max-height: 0;
    overflow: hidden;
    transition: all 0.2s ease-out;

    .content {
      transition: all 0.2s ease-in-out;
      opacity: 0;
      &.show {
        opacity: 1;
        transition: all 0.3s ease-in-out;
      }

      .sub-item {
        color: var(--secondary-bleu);
        padding: 16px 0;
        cursor: pointer;
        font-weight: 400;
        &:hover {
          color: var(--primary-blue);
        }
      }
    }

    &.toggle {
      max-height: 4000px;
      transition: all 0.5s ease-in;
    }
  }

  @media only screen and (min-width: ${Breakpoint.md}) {
    .ant-divider-horizontal {
      display: none;
    }
    .item {
      svg {
        display: none;
      }
      cursor: initial;
      margin-bottom: 8px;
    }
    .slideable-content {
      max-height: initial;
      .content {
        opacity: 1;
        padding: 0;
        .sub-item {
          padding: 4px 0;
          display: block;
        }
      }
    }
    .copyright {
      text-align: center;
      max-width: 55%;
      margin: 20px auto 5px;
    }
  }
`;

const Accordion = ({ title, subItems }) => {
  const [isToggled, toggleDropdown] = useState(false);
  return (
    <>
      <div
        role="button"
        tabIndex={-1}
        className="item"
        onClick={() => toggleDropdown(!isToggled)}
      >
        <span className="title">{title}</span>
        <PlusIcon />
      </div>
      <Divider />
      <div className={`slideable-content ${isToggled ? "toggle" : ""}`}>
        <ul className={`content ${isToggled ? "show" : ""}`}>
          {subItems &&
            subItems.map((item, index) => (
              <li key={index}>
                <Link
                  to={
                    title === "Par province"
                      ? `/entreprise?&sectors=&search=&sector=&type=seller&province=${item.id}`
                      : item.path
                  }
                  refresh
                  className="sub-item"
                  key={typeof item === "object" ? item.title : item}
                >
                  {item.title}
                </Link>
              </li>
            ))}
        </ul>
      </div>
    </>
  );
};
Accordion.propTypes = {
  title: PropTypes.string,
  subItems: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])
  )
};
Accordion.defaultProps = {
  title: "Accordion title",
  subItems: []
};

const footerItems = [
  {
    title: "A propos",
    subItems: [
      { title: "Conditions d'utilisation", path: "/tos" },
      { title: "Politique de confidentialité", path: "" },
      { title: "Démarrer mon entreprise", path: "" },
      { title: "Espace investisseurs", path: "" },
      { title: "Contact", path: "/contact" }
    ]
  },
  {
    title: "Annuaire des entreprises",
    subItems: [
      { title: "Formations", path: "" },
      { title: "Événements", path: "" },
      { title: "Offres d'emploi", path: "" },
      { title: "Fournisseurs", path: "" },
      { title: "Prestataires de service", path: "" },
      { title: "Réseau social", path: "" }
    ]
  },
  {
    title: "Par province"
  }
];

const Footer = ({ provinces }) => {
  return (
    <StylesFooter>
      <Row justify="space-around" className="container">
        {footerItems.map((item) => (
          <Col key={item.title} xs={24} sm={24} md={4}>
            <Accordion
              title={item.title}
              subItems={
                item.title !== "Par province" ? item.subItems : provinces
              }
            />
          </Col>
        ))}
      </Row>

      <div className="copyright">
        Copyright © 2020 Intaj Trademark. Propulsé et déployé par
        <span className="dbfy"> Digital Butterfly </span>
        pour le Centre Régional d&apos;Investissement de la région de
        l&apos;Oriental.
      </div>
    </StylesFooter>
  );
};
Footer.propTypes = {
  provinces: PropTypes.arrayOf(PropTypes.string)
};
Footer.defaultProps = {
  provinces: []
};

const mapStateToProps = (state) => ({
  provinces: state.home.information.provinces
});
export default connect(mapStateToProps, null)(Footer);
