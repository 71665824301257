import { call, put, takeEvery } from "redux-saga/effects";
import * as actionTypes from "./actionTypes";
import {
  setSearchData,
  setRequestSearchDataError,
  requestSearchSuccess,
  requestSearchError
} from "./actions";
import api from "../../utils/api";

function* getSearchData() {
  // NOTE: change this to the appropriate end point.
  try {
    const {
      data: { sectors, provinces },
      status
    } = yield call(api.get, "searchBarData");
    if (status === 200) {
      yield put(setSearchData({ sectors, provinces }));
    }
  } catch (e) {
    yield put(setRequestSearchDataError(e));
  }
}

function* search({ searchObject }) {
  try {
    const { data } = yield call(api.post, "search", searchObject);

    yield put(requestSearchSuccess(data));
  } catch (e) {
    console.error(e);
    yield put(requestSearchError(e));
  }
}

function* searchPage({ searchObject }) {
  try {
    const { data: data } = yield call(api.post, "search", searchObject);
    yield put(requestSearchSuccess(data));
  } catch (e) {
    console.error(e);
    yield put(requestSearchError(e));
  }
}

export function* getSearchDataWatcher() {
  yield takeEvery(actionTypes.REQUEST_SEARCH_DATA, getSearchData);

  yield takeEvery(actionTypes.REQUEST_SEARCH, search);
  yield takeEvery(actionTypes.REQUEST_SEARCH_PAGE, searchPage);
}
