export const SIGN_IN_REQUEST = "SIGN_IN_REQUEST";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS_SUCCESS";
export const SAVE_TOKEN = "SAVE_TOKEN";
export const SIGN_IN_ERROR = "SIGN_IN_ERROR";
export const SIGN_IN_CANCELLED = "SIGN_IN_CANCELLED";

export const LOG_OUT_REQUESTING = "LOG_OUT_REQUESTING";
export const LOG_OUT_REQUESTING_ERROR = "LOG_OUT_REQUESTING_ERROR";
export const LOG_OUT_REQUESTING_SUCCESS = "LOG_OUT_REQUESTING_SUCCESS";
export const DELETE_TOKEN = "DELETE_TOKEN";
export const REQUEST_RESET_PASSWORD = "REQUEST_RESET_PASSWORD";
export const REQUEST_RESET_PASSWORD_SUCCESS = "REQUEST_RESET_PASSWORD_SUCCESS";
export const VALIDATE_RESET_PASSWORD_TOKEN_REQUEST =
  "VALIDATE_RESET_PASSWORD_TOKEN";
export const VALIDATE_RESET_PASSWORD_TOKEN_STATUS =
  "VALIDATE_RESET_PASSWORD_TOKEN_STATUS";
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_STATUS = "RESET_PASSWORD_STATUS";

export const REQUEST_USER = "REQUEST_USER";
export const REQUEST_USER_SUCCESS = "REQUEST_USER_SUCCESS";
export const REQUEST_USER_ERROR = "REQUEST_USER_ERROR";

export const REQUEST_HOME_INFORMATION = "REQUEST_HOME_INFORMATION";
export const SET_HOME_INFORMATION = "SET_HOME_INFORMATION";
export const REQUEST_HOME_INFORMATION_ERROR = "REQUEST_HOME_INFORMATION_ERROR";
