export const REQUEST_INSCRIPTION_INFORMATION =
  "REQUEST_INSCRIPTION_INFORMATION";
export const SET_INSCRIPTION_INFORMATION = "SET_INSCRIPTION_INFORMATION";
export const ADD_ENTERPRISE_INFORMATION = "ADD_ENTERPRISE_INFORMATION";
export const ADD_ENTERPRISE_INFORMATION_SUCCESS =
  "ADD_ENTERPRISE_INFORMATION_SUCCESS";
export const ADD_ENTERPRISE_INFORMATION_ERROR =
  "ADD_ENTERPRISE_INFORMATION_SUCCESS";
export const REQUEST_ADD_ENTERPRISE = "REQUEST_ADD_ENTERPRISE";

export const REQUEST_GET_PRODUCTS = "REQUEST_GET_PRODUCTS";

export const SET_PRODUCTS = "SET_PRODUCTS";
