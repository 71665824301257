export const REQUEST_ENTERPRISE = "REQUEST_ENTERPRISE";
export const SET_ENTERPRISE = "SET_ENTERPRISE";
export const REQUEST_ENTERPRISE_ERROR = "REQUEST_ENTERPRISE_ERROR";
export const DELETE_OFFER = "DELETE_OFFER";
export const DELETE_OFFER_SUCCESS = "DELETE_OFFER_SUCCESS";
export const EDIT_OFFER = "EDIT_OFFER";
export const EDIT_OFFER_SUCCESS = "EDIT_OFFER_SUCCESS";
export const DELETE_NEED = "DELETE_NEED";
export const DELETE_NEED_SUCCESS = "DELETE_NEED_SUCCESS";
export const EDIT_NEED = "EDIT_NEED";
export const EDIT_NEED_SUCCESS = "EDIT_NEED_SUCCESS";

export const ADD_OFFER = "ADD_OFFER";
export const ADD_OFFER_SUCESS = "ADD_OFFER_SUCCESS";

export const ADD_NEED = "ADD_NEED";
export const ADD_NEED_SUCCESS = "ADD_NEED_SUCCESS";

export const SHOW_MODAL = "SHOW_MODAL";

export const SHOW_EDIT_MODAL = "SHOW_EDIT_MODAL";
